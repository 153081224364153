import { observer } from "mobx-react-lite";
import { useStore } from "../../contexts/StoreProvider";
import { Fragment, useCallback, useEffect, useState } from "react";
import { PageLimitOptions, initialPageLimit } from "../../constants/pageLimitOptions.constant";
import { IPageInfo } from "../../models/IPageInfo";
import PageLoader from "../../shared/Loader/PageLoader";
import { toast } from "react-toastify";
import { Link, useLocation, useParams } from "react-router-dom";
import SearchField from "../../shared/SearchField/SearchField";
import SocialShare from "../../shared/SocialShare/SocialShare";
import OtherFeatures from "../../shared/OtherFeatures/OtherFeatures";
import Pagination from "../../shared/Pagination/Pagination";
import { getTrimmedName } from "../../helpers/links.helper";
import Preview from "../../shared/CodePreview/Preview";

interface IProps {
    className: string;
}

const PostImages = (props: IProps) => {
    const [isLinkCopied, setIsLinkCopied] = useState(false);

    const params = useParams();

    const categoryName = params.categoryName ? params.categoryName.replaceAll('-', ' ') : '';
    const tagName = params.tagName ? params.tagName.replaceAll('-', ' ') : '';

    useEffect(() => {
        if (isLinkCopied) {
            toast.success('Link copied successfully');
            setIsLinkCopied(false);
        }
    }, [isLinkCopied]);

    const { postsStore } = useStore();
    const { getAllPosts, allPosts, inProgress, error, reset, getCategoryDescription, postsData, getTagDescription } = postsStore;

    const [pageLimit, setPageLimit] = useState<number>(initialPageLimit);
    const [searchText, setSearchText] = useState('');
    const [pageInfo, setPageInfo] = useState<IPageInfo>({
        currentPage: 1,
        totalPages: 1
    });

    const [disablePagination, setDisablePagination] = useState<boolean>(false);

    useEffect(() => {
        setDisablePagination(true);
        getAllPosts(pageInfo.currentPage, pageLimit, searchText, categoryName, tagName);
    }, [getAllPosts, pageInfo.currentPage, pageLimit, searchText, categoryName, tagName]);

    useEffect(() => {
        setPageInfo(pageInfo => ({ ...pageInfo, totalPages: postsData?.TotalPages }));
        setDisablePagination(false);
    }, [allPosts, postsData.TotalPages]);

    useEffect(() => {
        if (error) {
            toast.error(error);
            reset();
        }
    }, [error, reset]);

    const onSearchTextChanged = (text: string) => { setSearchText(text) };

    const onPageLimitChanged = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        setPageLimit(parseInt(evt.target.value));
    }

    const onPageChanged = useCallback((pageNumber: number) => {
        setPageInfo(pageInfo => ({ ...pageInfo, currentPage: pageNumber }));
    }, []);

    useEffect(() => {
        onPageChanged(1);
    }, [pageLimit, onPageChanged])

    const totalPages = pageInfo.totalPages > 0 ? pageInfo.totalPages : 1;

    return <>

        <div className="mx-auto" style={{ maxWidth: '680px' }}>
            {
                inProgress ? <PageLoader /> : <>
                    {
                        <>
                            {categoryName?.length ? <div>
                                <h1>{categoryName}</h1>
                                <div>{getCategoryDescription}</div>
                            </div> : <></>}
                            {tagName?.length ? <div>
                                <h1>{tagName}</h1>
                                <div>{getTagDescription}</div>
                            </div> : <></>}
                            <>
                                <div className={props.className}>
                                    {/* <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">  */}
                                    {allPosts && allPosts.map(post => {
                                        const trimmedPostTitle = getTrimmedName(post.Title);

                                        const postLink = `${window.location.origin}/${trimmedPostTitle}`

                                        return <Fragment key={post.Id}>
                                            <div className="col-12 mb-4">
                                                <div className="card h-100 rounded-4 overflow-hidden shadow-sm border">
                                                    <div className="card-header bg-white border-bottom">
                                                        <h2 className="fs-6 my-2">
                                                            <Link to={`/${trimmedPostTitle}`} className="d-block">
                                                                {post.Title}
                                                            </Link>
                                                        </h2>
                                                    </div>
                                                    <div className="card-body position-relative">
                                                        {/* <Link to={`/${trimmedPostTitle}`} className="d-block text-center">
                                                            <img src={`http://localhost:7001/${post.ImageUrl}`} alt={post.Title} className="img-fluid" />
                                                        </Link> */}
                                                        <Preview
                                                            htmlCode={post.HtmlCode}
                                                            cssCode={post.CssCode}
                                                            jsCode={post.JsCode}
                                                            headTag={post.HeadTag}
                                                        />
                                                    </div>

                                                    {/* <div className="card-footer bg-white border-top d-flex align-items-center">
                                                        <div className="me-auto">
                                                            <a href="#" className="btn btn-light btn-sm">Preview</a>
                                                            <a href="#" className="btn btn-light btn-sm ms-2">Get Hosting</a>                                                            
                                                        </div>

                                                        <div className="sharing-btns">
                                                            <SocialShare linkToCopy={postLink} setIsLinkCopied={setIsLinkCopied} />
                                                        </div>

                                                        <div className="other-features">
                                                            <OtherFeatures htmlCode={post.HtmlCode} cssCode={post.CssCode} jsCode={post.JsCode} postTitle={trimmedPostTitle} headTag={post.HeadTag} />
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </Fragment>
                                    })}

                                </div>
                            </>
                        </>
                    }
                </>
            }
        </div>

        <div className="col-12">
            <div className="mx-auto d-table mt-5 bg-white p-2 rounded-5 shadow-sm">
                <ul className="pagination">
                    <Pagination onPageChanged={onPageChanged} totalPages={totalPages} currentPage={pageInfo.currentPage} disablePagination={disablePagination} />
                </ul>
            </div>
        </div>

    </>
}

export default observer(PostImages);