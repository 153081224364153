import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { useStore } from "../../contexts/StoreProvider";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PageLoader from "../../shared/Loader/PageLoader";
import CodeHighlighterEditor from "../../shared/CodeHighlighterEditor/CodeHighlighterEditor";
import CopyCode from "../../shared/CopyCode/CopyCode";
import HzAds from '../../assets/Images/hz-ads-1.gif';
import SocialShare from "../../shared/SocialShare/SocialShare";
import OtherFeatures from "../../shared/OtherFeatures/OtherFeatures";
import withRouter from "../../shared/RenderRoutes/WithRouter";
import { IPost } from "../../core/models/response/IPostsResponse";
import { getTrimmedName } from "../../helpers/links.helper";
import Preview from "../../shared/CodePreview/Preview";

const PostDetail = () => {
    const { postsStore } = useStore();
    const { getPostByTitle, getPostDetail, resetGetPostDetail, postDetailError, postDetailInProgress, allPosts } = postsStore;

    const postTitle = window.location.pathname.substring(1).replaceAll('-', ' ');

    const [isInFullScreen, setIsInFullScreen] = useState(false);

    useEffect(() => {
        getPostByTitle(postTitle);
    }, [getPostByTitle, postTitle]);

    useEffect(() => {
        if (postDetailError) {
            toast.error(postDetailError);
            resetGetPostDetail();
        }
    }, [postDetailError, resetGetPostDetail]);

    const [htmlCopied, setHtmlCopied] = useState(false);
    const [cssCopied, setCssCopied] = useState(false);
    const [jsCopied, setJsCopied] = useState(false);
    const [headTagCopied, setHeadTagCopied] = useState(false);

    const currentPost = allPosts.find(post => post.Id === getPostDetail.Id);

    let currentPostIndex: number = -1;

    if (currentPost) {
        currentPostIndex = allPosts.indexOf(currentPost);
    }

    const previousPost = currentPostIndex >= 0 ? allPosts[currentPostIndex - 1] : undefined;
    const nextPost = currentPostIndex >= 0 ? allPosts[currentPostIndex + 1] : undefined;

    const [isLinkCopied, setIsLinkCopied] = useState(false);

    useEffect(() => {
        if (isLinkCopied) {
            toast.success('Link copied successfully');
            setIsLinkCopied(false);
        }
    }, [isLinkCopied]);

    const getTrimmedTitle = (post: IPost) => {
        return getTrimmedName(post.Title);
    }

    return <>
        {postDetailInProgress ? <PageLoader /> : <>
            <div className="mx-auto" style={{ maxWidth: '900px' }}>
                <div className={`${isInFullScreen ? '' : 'row'}`}>
                    {/* <div className="col-6">
                        <PostImages className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4" />
                    </div> */}
                    <div className="col-12 rounded-4 overflow-hidden shadow-sm bg-white border">
                        <div className="row">
                            <div className="single-post-header bg-light py-2 px-3 border-bottom mb-2 d-flex align-items-center">
                                <div className="me-auto">
                                    <h3 className="fs-6 m-0"><Link to={`/category/${getTrimmedName(getPostDetail.CategoryName)}`}>{getPostDetail.CategoryName}</Link></h3>
                                </div>
                                <div className="btn-group">
                                    <Link style={{ pointerEvents: previousPost ? 'auto' : 'none' }} to={previousPost ? `/${getTrimmedTitle(previousPost)}` : ''} className="btn px-2 py-0">
                                        <i className="bi bi-arrow-left-circle text-secondary fs-4"></i>
                                    </Link>
                                    <Link style={{ pointerEvents: nextPost ? 'auto' : 'none' }} to={nextPost ? `/${getTrimmedTitle(nextPost)}` : ''} className="btn px-2 py-0">
                                        <i className="bi bi-arrow-right-circle text-secondary fs-4"></i>
                                    </Link>
                                    {/* <button className="btn px-2 py-0"><i className="bi bi-arrow-left-circle text-secondary fs-4"></i></button>
                                    <button className="btn px-2 py-0"><i className="bi bi-arrow-right-circle text-secondary fs-4"></i></button> */}
                                </div>
                                <div className="sharing-btns mx-2">
                                    <SocialShare linkToCopy={window.location.href} setIsLinkCopied={setIsLinkCopied} />
                                </div>

                                <div className="other-features">
                                    <OtherFeatures htmlCode={getPostDetail.HtmlCode} cssCode={getPostDetail.CssCode} jsCode={getPostDetail.JsCode} postTitle={getTrimmedTitle(getPostDetail)} headTag={getPostDetail.HeadTag} />
                                </div>
                                {/* <div className="fullscreen-mode" onClick={() => setIsInFullScreen(!isInFullScreen)}>
                                    <button className="btn px-2 py-0"><i className="bi bi-arrows-fullscreen text-secondary fs-6"></i></button>
                                </div> */}
                            </div>
                        </div>
                        <div className="thumbnail my-2">
                            <Preview
                                htmlCode={getPostDetail.HtmlCode}
                                cssCode={getPostDetail.CssCode}
                                jsCode={getPostDetail.JsCode}
                                headTag={getPostDetail.HeadTag}
                            />
                            {/* <img src={`http://localhost:7001/${getPostDetail.ImageUrl}`} alt={getPostDetail.Title} className="rounded-3 shadow border img-fluid mx-auto d-block" /> */}
                        </div>
                        <div className="ads py-4">
                            <img src={HzAds} alt='logo' className="header-logo rounded-3 shadow border img-fluid mx-auto d-block" />
                        </div>
                        <h1 className="h3">{getPostDetail.Title}</h1>
                        <p>{getPostDetail.Description}</p>

                        <div className="code-tabs mb-3 mt-4 border-top border-bottom">
                            <div className="nav nav-tabs border-0" id="nav-tab" role="tablist">
                                {getPostDetail.HtmlCode.length ? <button className="nav-link active" id="nav-html-tab" data-bs-toggle="tab" data-bs-target="#nav-html" type="button" role="tab" aria-controls="nav-html" aria-selected="true">HTML</button> : <></>}
                                {getPostDetail.CssCode.length ? <button className="nav-link" id="nav-css-tab" data-bs-toggle="tab" data-bs-target="#nav-css" type="button" role="tab" aria-controls="nav-css" aria-selected="false">CSS</button> : <></>}
                                {getPostDetail.JsCode.length ? <button className="nav-link" id="nav-javascript-tab" data-bs-toggle="tab" data-bs-target="#nav-javascript" type="button" role="tab" aria-controls="nav-javascript" aria-selected="false">JavaScript</button> : <></>}
                                {getPostDetail.HeadTag.length ? <button className="nav-link" id="nav-headTag-tab" data-bs-toggle="tab" data-bs-target="#nav-headTag" type="button" role="tab" aria-controls="nav-headTag" aria-selected="false">Head Tag</button> : <></>}
                            </div>
                        </div>

                        <div className="tab-content w-100 float-start" id="nav-tabContent">
                            {getPostDetail.HtmlCode.length ? <div className="tab-pane fade show active" id="nav-html" role="tabpanel" aria-labelledby="nav-html-tab">
                                <div className="position-relative mb-5">
                                    <div className="highlighter-header rounded-top">
                                        <span className="highlighter-heading">HTML</span>
                                        <CopyCode copyValue={getPostDetail.HtmlCode} setStateCopied={setHtmlCopied} />
                                    </div>
                                    {htmlCopied ? <span className="content-Copied">Copied</span> : null}

                                    <CodeHighlighterEditor
                                        value={getPostDetail.HtmlCode}
                                        mode={"xml"}
                                        readonly={true}
                                    />
                                </div>
                            </div> : <></>}
                            {getPostDetail.CssCode.length ? <div className="tab-pane fade" id="nav-css" role="tabpanel" aria-labelledby="nav-css-tab">
                                <div className="position-relative mb-5">
                                    <div className="highlighter-header rounded-top">
                                        <span className="highlighter-heading">CSS</span>
                                        <CopyCode copyValue={getPostDetail.CssCode} setStateCopied={setCssCopied} />
                                    </div>
                                    {cssCopied ? <span style={{ color: 'green' }}>Copied</span> : null}

                                    <CodeHighlighterEditor
                                        value={getPostDetail.CssCode}
                                        mode={"css"}
                                        readonly={true}
                                    />
                                </div>
                            </div> : <></>}
                            {getPostDetail.JsCode.length ? <div className="tab-pane fade" id="nav-javascript" role="tabpanel" aria-labelledby="nav-javascript-tab">
                                <div className="position-relative">
                                    <div className="highlighter-header rounded-top">
                                        <span className="highlighter-heading">JS</span>
                                        <CopyCode copyValue={getPostDetail.JsCode} setStateCopied={setJsCopied} />
                                    </div>
                                    {jsCopied ? <span style={{ color: 'green' }}>Copied</span> : null}

                                    <CodeHighlighterEditor
                                        value={getPostDetail.JsCode}
                                        mode={"javascript"}
                                        readonly={true}
                                    />
                                </div>
                            </div> : <></>}
                            {getPostDetail.HeadTag.length ? <div className="tab-pane fade" id="nav-headTag" role="tabpanel" aria-labelledby="nav-headTag-tab">
                                <div className="position-relative">
                                    <div className="highlighter-header rounded-top">
                                        <span className="highlighter-heading">Head Tag</span>
                                        <CopyCode copyValue={getPostDetail.HeadTag} setStateCopied={setHeadTagCopied} />
                                    </div>
                                    {headTagCopied ? <span style={{ color: 'green' }}>Copied</span> : null}

                                    <CodeHighlighterEditor
                                        value={getPostDetail.HeadTag}
                                        mode={"xml"}
                                        readonly={true}
                                    />
                                </div>
                            </div> : <></>}
                        </div>

                        <div className="ads py-4">
                            <img src={HzAds} alt='logo' className="header-logo rounded-3 shadow border img-fluid mx-auto d-block" />
                        </div>

                    </div>
                </div>
            </div>
        </>}
    </>
}

export default withRouter(observer(PostDetail));