import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import LeftSidebar from "../LeftSidebar/LeftSidebar";
import RightSidebar from "../RightSidebar/RightSidebar";

interface IProps {
    Component: React.JSX.Element
}

const Layout = (props: IProps) => {
    return <div>

        <Header />

        <div className="container">
            <div className="row">
                <div className="col" style={{ maxWidth: '240px' }}>
                    <LeftSidebar />
                </div>
                <div className="col">
                    <div id="main-content">
                        <div className="row">
                            <div className="col">
                                {props.Component}
                            </div>
                            <div className="col" style={{ maxWidth: '320px' }}>
                                <RightSidebar />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer />

    </div>
}

export default Layout;