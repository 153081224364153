import { Route, Routes } from "react-router-dom";
import Home from "../../modules/MainSection/Home";
import PostImages from "../../modules/MainSection/PostImages";
import Layout from "../Layout/Layout";
import AddPost from "../../modules/AddPost/AddPost";
import PostDetail from "../../modules/PostDetail/PostDetail";
import Dashboard from "../../modules/Dashboard/Dashboard";
import AddCategory from "../../modules/AddCategory/AddCategory";
import AddTag from "../../modules/AddTag/AddTag";
import { observer } from "mobx-react-lite";
import PostsTable from "../../modules/AllPosts/PostsTable";
import CategoriesTable from "../../modules/AllCategories/CategoriesTable";
import TagsTable from "../../modules/AllTags/TagsTable";
import AboutUs from "../../modules/MainSection/AboutUs";
import Disclaimer from "../../modules/MainSection/Disclaimer";
import ContactUs from "../../modules/MainSection/ContactUs";
import PrivacyPolicy from "../../modules/MainSection/PrivacyPolicy";


const RenderRoutes = () => {
    return <Routes>
        <Route path="/" element={<Layout Component={<Home />} />} />
        <Route path="About-Us" element={<Layout Component={<AboutUs />} />} />
        <Route path="Contact-Us" element={<Layout Component={<ContactUs />} />} />
        <Route path="Disclaimer" element={<Layout Component={<Disclaimer />} />} />
        <Route path="privacy-policy" element={<Layout Component={<PrivacyPolicy />} />} />
        <Route path="/PostImages" element={<Layout Component={<PostImages className="posts" />} />} />
        <Route path="/addPost" element={<AddPost />} />
        <Route path="/addCategory" element={<AddCategory />} />
        <Route path="/addTag" element={<AddTag />} />
        <Route path="/:postTitle" element={<Layout Component={<PostDetail />} />} />
        <Route path="/category/:categoryName" element={<Layout Component={<PostImages className="posts" />} />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/allPosts" element={<PostsTable />} />
        <Route path="/editPost/:postTitle" element={<AddPost />} />
        <Route path="/editCategory/:categoryName" element={<AddCategory />} />
        <Route path="/editTag/:tagName" element={<AddTag />} />
        <Route path="/allCategories" element={<CategoriesTable />} />
        <Route path="/allTags" element={<TagsTable />} />
        <Route path="/tag/:tagName" element={<Layout Component={<PostImages className="posts" />} />} />
    </Routes>
}

export default observer(RenderRoutes);