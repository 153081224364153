export const getFullHtmlContent = (htmlCode: string, cssCode: string, jsCode: string, headTag: string) => {
    let fullHtmlContent = '';
    
    if (htmlCode.length) {
        fullHtmlContent =
            `<!DOCTYPE html>
        <html lang="en">
        <head>
        ${cssCode.length ? '<link rel="stylesheet" href="./style.css" />' : ''}
        </head>
        <body>
            ${htmlCode}
        </body>
        ${jsCode.length ? `<script>${jsCode}</script>` : ''}
        </html>`;

        if (headTag.length) {
            fullHtmlContent =
                `<!DOCTYPE html>
            <html lang="en">
            <head>
                ${headTag}
                ${cssCode.length ? '<link rel="stylesheet" href="./style.css" />' : ''}
            </head>
            <body>
                ${htmlCode}
            </body>
            </html>`
        }
    }

    return fullHtmlContent;
}