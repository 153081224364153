const Loader = () => {
    return (
        <span
            aria-hidden="true"
            style={{ color: "#2f6fc7" }}
        >
            loader
        </span>
    )
};

export default Loader;