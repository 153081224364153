import { NavLink } from "react-router-dom";


const Home = () => {
    return <>           
                
                    <h1>Home Page</h1>                

                    <NavLink to='/PostImages' className={"btn btn-primary"}>Web Components</NavLink>
              
            
    </>
}

export default Home;