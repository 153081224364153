import categoryStore, { CategoryStore } from "./category-store";
import postsStore, { PostsStore } from "./posts-store";
import tagsStore, { TagsStore } from "./tags-store";

export type RootStore = {
    postsStore: PostsStore;
    categoryStore: CategoryStore;
    tagsStore: TagsStore;
}

const rootStore: RootStore = {
    postsStore: postsStore,
    categoryStore: categoryStore,
    tagsStore: tagsStore
}

export default rootStore;