import Logo from '../../assets/Images/markuptag-logo-4.png';

const Footer = () => {
    return <footer className='mt-5 bg-white'>
        <div className="container">
            <div className="row py-4">
                <div className="col-md-4">
                    <h2 className="fs-5">We Recommend</h2>
                    <ul className="list-group">
                        <li className="list-group-item border-0 p-1"><a href="/">Get Hosting</a></li>
                        <li className="list-group-item border-0 p-1"><a href="/">UI Material</a></li>
                    </ul>
                </div>

                <div className="col-md-4">
                    <h2 className="fs-5">Company</h2>
                    <ul className="list-group">
                        <li className="list-group-item border-0 p-1"><a href="/">About Us</a></li>
                        <li className="list-group-item border-0 p-1"><a href="/">Contact Us</a></li>
                        <li className="list-group-item border-0 p-1"><a href="/">Disclaimer</a></li>
                        <li className="list-group-item border-0 p-1"><a href="/">Privacy Policy</a></li>
                    </ul>
                </div>

                <div className="col-md-4">
                    <a href="/">
                        <img src={Logo} alt='logo' style={{maxWidth: "220px"}}/>
                    </a>
                </div>                
            </div>            
        </div>
        
        <p className="text-center m-0 py-2 text-secondary border-top">© 2024 <a href="/" className="text-secondary">MarkupTag</a> | All Rights Reserved</p>
            
    </footer>
}

export default Footer;