import Logo from '../../assets/Images/markuptag-logo-white.png';
import SearchField from '../SearchField/SearchField';
import './Header.css';
const Header = () => {
    function onSearchTextChanged(text: string): void {
        throw new Error('Function not implemented.');
    }

    return <header>

        <nav className="navbar navbar-expand-sm navbar-primary fixed-top bg-primary border-bottom" data-bs-theme="dark">
            <div className="container">
                <div className='row w-100'>
                    <div className='col-8'>
                        <a className="navbar-brand" href="/">
                            <img src={Logo} alt='logo' className="header-logo" style={{maxWidth: "170px"}}/>
                        </a>
                    </div>
                    {/* <div className='col-6'>
                        <div className="mx-auto" style={{maxWidth: '500px'}}>
                            <SearchField onSearchTextChanged={onSearchTextChanged} />
                        </div>
                    </div> */}
                    <div className='col-4'>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                            <span className="navbar-toggler-icon"></span>
                        </button>                        
                        <div className="collapse navbar-collapse" id="mynavbar">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <a className="nav-link" href="/">Get Hosting</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/">UI Material</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </nav>            
        
    </header>
}

export default Header;